<template>
  <div class="container-fluid p-4">
    <div class="d-flex justify-content-between">
      <h1>Manage Visitors</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
      </div>
    </div>
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column">
        <!-- search visitor -->
        <b-card class="bg-surface-2">
          <b-row class="d-flex justify-content-between w-100 mb-2" no-gutters>
            <b-col>
              <b-form-input
                class="text-truncate"
                id="input-1"
                v-model="$v.searchForm.keyword.$model"
                placeholder="Search by name,email, ic number and passport number "
                v-on:keyup.enter="getSearchVisitor"
                :state="validateFormSearch('keyword')"
                aria-describedby="searchForm.keyword_feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="searchForm.keyword_feedback"
              >
                Your search must be at least 3 character or 3 digit number.
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-end" no-gutters>
            <b-button
              size="sm"
              variant="dark"
              @click="clearSearch"
              class="d-flex justify-content-end mr-1"
              >Clear
            </b-button>
            <router-link
              :to="{ query: { keyword: searchForm.keyword } }"
              v-slot="{ href, route, getSearchVisitor }"
              custom
            >
              <b-button
                size="sm"
                variant="light"
                @click="getSearchVisitor"
                v-model="searchForm.keyword"
                :href="href"
              >
                Search
              </b-button>
            </router-link>
          </b-row>
        </b-card>
        <!-- add visitor -->
        <div class="d-flex justify-content-between pt-4 pb-2">
          <div class="d-flex justify-content-start">
            <b-button
              class="textColor"
              variant="primary"
              size="sm"
              v-b-modal.form-add-visitor
              @click="showAddVisitorForm()"
            >
              <i class="fas fa-plus mr-1"></i>Add Visitor
            </b-button>
          </div>
        </div>
        <!-- Main table element -->
        <div class="d-flex justify-content-between w-100">
          <!-- :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" -->
          <b-table
            :busy="busy"
            :items="visitorsList"
            :fields="visitorsTableFields"
            :filter-included-fields="filterOn"
            :filter="searchTerms"
            class="tableBorder"
            stacked="md"
            responsive
            show-empty
            striped
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(index)="row">
              {{ row.index + 1 + (currentPage - 1) * perPage }}
            </template>
            <template #cell(image)="row">
              <div class="visitor-card-face-image">
                <img :src="showImage(row.item.face_image)" fluid />
              </div>
            </template>
            <template #cell(nric_number)="row">
              {{ row.item.nric_number | dashForEmptyText }}
            </template>
            <template #cell(passport_number)="row">
              {{ row.item.passport_number | dashForEmptyText }}
            </template>
            <template #cell(name)="row">
              {{ row.item.name | humanizeText }}
            </template>
            <template #cell(email)="row">
              {{ row.item.email }}
            </template>
            <template #cell(contact_number)="row">
              {{ row.item.contact_number | dashForEmptyText }}
            </template>
            <template #cell(is_blocklisted)="row">
              <b-badge
                v-if="row.item.is_blocklisted"
                pill
                variant="secondary"
                class="p-2"
                ><strong style="font-size: var(--font-size-normal)"
                  >Blocked</strong
                >
              </b-badge>
              <b-badge v-else pill variant="success" class="p-2"
                ><strong style="font-size: var(--font-size-normal)"
                  >Active</strong
                ></b-badge
              >
            </template>
            <template #cell(actions)="row">
              <b-button-group size="sm">
                <!-- edit visitor  -->
                <b-button
                  variant="secondary"
                  size="sm"
                  v-b-modal.form-update-visitor
                  v-b-tooltip.hover
                  :title="handleTitle(row.item.disabled_update)"
                  @click="showUpdateVisitorForm(row.item, row.index)"
                  :disabled="row.item.disabled_update"
                  class="mr-1"
                >
                  <i class="fas fa-edit"></i>
                </b-button>
                <!-- unblock visitor  -->
                <b-button
                  v-if="row.item.is_blocklisted"
                  variant="primary"
                  size="sm"
                  v-b-tooltip.hover
                  title="Unblock"
                  @click="confirmBlock(row.item)"
                  class="mr-1"
                >
                  <i class="fas fa-user-check"></i>
                </b-button>
                <!-- block visitor  -->
                <b-button
                  v-else
                  variant="secondary"
                  size="sm"
                  v-b-tooltip.hover
                  title="Block"
                  @click="confirmBlock(row.item)"
                  :disabled="row.item.is_block_disabled"
                  class="mr-1"
                >
                  <i class="fas fa-ban"></i>
                </b-button>
                <!-- delete visitor  -->
                <b-button
                  variant="secondary"
                  size="sm"
                  @click="confirmDelete(row.item, row.index)"
                  v-b-tooltip.hover
                  title="Delete Visitor"
                  :disabled="row.item.is_disabled"
                  class="mr-1"
                >
                  <i class="fas fa-trash-alt"></i>
                </b-button>
                <!-- visit history visitor  -->
                <router-link
                  v-if="searchForm.keyword != null"
                  :to="{
                    name: 'visit-history',
                    params: { id: row.item.id },
                    query: {
                      parents_url: 'visitors',
                      keyword: searchForm.keyword
                    }
                  }"
                  v-slot="{ href, route, navigate }"
                  custom
                >
                  <b-button
                    variant="secondary"
                    size="sm"
                    :href="href"
                    @click="navigate"
                    v-b-tooltip.hover
                    title="Visit History"
                    :disabled="row.item.has_history"
                  >
                    <i class="fas fa-history"></i>
                  </b-button>
                </router-link>
                <router-link
                  v-else
                  :to="{
                    name: 'visit-history',
                    params: { id: row.item.id },
                    query: {
                      parents_url: 'visitors'
                    }
                  }"
                  v-slot="{ href, route, navigate }"
                  custom
                >
                  <b-button
                    variant="secondary"
                    size="sm"
                    :href="href"
                    @click="navigate"
                    v-b-tooltip.hover
                    title="Visit History"
                    :disabled="row.item.has_history"
                  >
                    <i class="fas fa-history"></i>
                  </b-button>
                </router-link>
              </b-button-group>
            </template>
          </b-table>
        </div>
        <!-- pagination -->
        <div
          class="d-flex justify-content-between w-100"
          v-if="!busy && !search"
        >
          <div class="d-flex">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              @change="handlePerPageChange"
            ></b-form-select>
            Per Page
          </div>

          <div class="d-flex">
            <b-pagination
              size="sm"
              v-if="perPage !== 'all'"
              class="d-flex"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </div>
        </div>
      </div>
      <!-- add visitor-->
      <b-modal
        id="form-add-visitor"
        title="Add Visitor"
        ref="formAddVisitor"
        centered
        hide-footer
        :no-close-on-backdrop="true"
        @close="stopCameraModal()"
      >
        <addVisitor
          ref="referencesChild"
          :referenceParent="this.$refs"
          :getPreference="this.getPreferences"
          :getCountryCode="this.getCountryCode"
          :isVisitor="true"
        ></addVisitor>
      </b-modal>
      <!-- update visitor-->
      <b-modal
        title="Update Visitor"
        id="form-update-visitor"
        ref="formUpdateVisitor"
        centered
        hide-footer
        :no-close-on-backdrop="true"
        @close="stopCameraModal()"
      >
        <updateVisitor
          ref="referencesChild"
          :referenceParent="this.$refs"
          :updateData="formUpdate"
          :idUpdate="formUpdateID"
          :idType="selectedIDType"
          :idName="selectedIDName"
          :showPhoto="updateShowUploadPhoto"
          :getPreference="this.getPreferences"
          :getCountryCode="this.getCountryCode"
        ></updateVisitor>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
  between,
  helpers
} from "vuelidate/lib/validators";

import jQuery from "jquery";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import addVisitor from "@/components/SmartVMS/Visitor/AddVisitor.vue";
import updateVisitor from "@/components/SmartVMS/Visitor/UpdateVisitor.vue";

let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

/* 
import Compressor from "compressorjs";
import * as imageConversion from "image-conversion";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'; 

let utils = require("@/assets/global/js/utils.js");
const username_regex = helpers.regex('username_regex', /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/);
const regex_nric = helpers.regex(regex_nric, /^\d{6}-\d{2}-\d{4}$/);
*/

export default {
  mixins: [validationMixin],
  components: {
    addVisitor,
    updateVisitor
    /* FontAwesomeIcon: 'font-awesome-icon' */
  },
  data: function () {
    return {
      data_visitor: {},
      visitorsList: [],
      visitorsTableFields: [
        {
          key: "index",
          label: "No.",
          sortDirection: "desc",
          thStyle: { width: "3%" }
        },
        {
          key: "image",
          label: "Profile Picture",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "nric_number",
          label: "NRID Number",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "passport_number",
          label: "Passport Number",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "contact_number",
          label: "Contact Number",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "is_blocklisted",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        { key: "actions", label: "Actions", thStyle: { width: "auto" } }
      ],

      sortBy: "id",
      sortDirection: "desc",
      sortDesc: false,

      // table search
      busy: false,
      search: false,
      searchTerms: "",
      selected: [],
      searchForm: {
        keyword: null
      },

      // pagination
      totalRows: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      // pageOptions: [5, 10, 15, { value: "all", text: "All" }],
      filter: null,
      filterOn: ["name", "nric_number", "passport_number"],
      defaultImage: require("@/assets/global/images/icons/profile_sample.jpg"),

      result: null,
      blockErrorMessage: null,
      is_blocklisted: false,
      isDisabled: false,
      cameraType: null,

      selectedIDName: null,
      selectedIDType: "passport_number",

      showTakePhoto: false,
      updateShowTakePhoto: false,
      showUploadPhoto: false,
      updateShowUploadPhoto: false,
      capturedPhoto: null,
      updateCapturedPhoto: null,
      uploadedPhoto: null,
      updateUploadedPhoto: null,

      formAdd: {
        face_image: null,
        name: null,
        email: null,
        nric_number: null,
        passport_number: null,
        contact_number: null,
        custom_id: null,
        profile: {
          gender: null,
          car_plate_no: null,
          company: null,
          fingerprint: {
            template1: null,
            template2: null
          }
        }
      },

      formUpdateID: null,
      formUpdate: {
        face_image: null,
        name: null,
        email: null,
        nric_number: null,
        passport_number: null,
        contact_number: null,
        custom_id: null,
        profile: {
          gender: null,
          car_plate_no: null,
          company: null,
          fingerprint: {
            template1: null,
            template2: null
          }
        }
      }
      //getPreferences: {}
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getCountryCode: "session/getCountryCode",
      getPreferences: "vmsPreferences2Module/getPreferences"
    }),
    getVisitors() {
      return this.$store.getters["visitor/getVisitors"](null);
    }
  },
  watch: {
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 5;
      }
      this.getVisitorsList();
    },
    getVisitors: function (n, o) {
      this.visitorsList = n;
      console.log("this.visitorsList", this.visitorsList);
    }
  },
  validations: {
    searchForm: {
      keyword: { minLength: minLength(3) }
    }
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 5;
    }
  },
  mounted: function () {
    this.$store.dispatch("vmsPreferences2Module/getAllPreferences");
    if (this.$route.query.keyword != undefined) {
      //get auto search visitor
      this.searchForm.keyword = this.$route.query.keyword;
      this.getSearchVisitor();
    } else {
      // get visitor
      this.getVisitorsList();
    }
  },

  methods: {
    //get visitor list
    getVisitorsList() {
      console.log("getPreferences", this.getPreferences);
      this.$store.dispatch("visitor/resetVisitor");

      this.busy = true;
      let API_URL = this.getAPIServerURL + "/api/visitorservice/visitor/all/";
      const client = this.$root.getAjaxFetchClient();
      let query = {};
      if (this.perPage === "all") {
        query = null;
      } else {
        query = {
          page_index: this.currentPage,
          page_size: this.perPage
        };
      }
      client
        .getRequest(API_URL, query)
        .then(async (data) => {
          let validUrl = "";
          let result = data.result;
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;
                this.busy = false;
                this.search = false;
                break outer_loop;
              }
            }
          }
          if (result[validUrl] != undefined) {
            for (let key2 in result[validUrl].visitors) {
              //get visitor info
              result[validUrl].visitors[key2]["blocklist"] = null;
              result[validUrl].visitors[key2]["is_disabled"] = false;
              result[validUrl].visitors[key2]["has_history"] = false;
              result[validUrl].visitors[key2]["disabled_update"] = false;
              result[validUrl].visitors[key2]["is_block_disabled"] = false;

              this.$store.dispatch(
                "visitor/getVisitHistory",
                result[validUrl].visitors[key2]
              );

              if (
                result[validUrl].visitors[key2].nric_number != null ||
                result[validUrl].visitors[key2].passport_number != null ||
                result[validUrl].visitors[key2].contact_number != null
              ) {
                this.$store.dispatch(
                  "visitor/getCheckBlock",
                  result[validUrl].visitors[key2]
                );

                this.$store.dispatch(
                  "visitor/getVmsPreferences",
                  result[validUrl].visitors[key2]
                );

                this.$store.dispatch(
                  "visitor/addVisitor",
                  result[validUrl].visitors[key2]
                );
              }

              if (
                result[validUrl].visitors[key2].nric_number == null &&
                result[validUrl].visitors[key2].passport_number == null &&
                result[validUrl].visitors[key2].contact_number == null
              ) {
                this.$store.dispatch(
                  "visitor/addVisitor",
                  result[validUrl].visitors[key2]
                );
              }

              if (
                result[validUrl].visitors[key2].nric_number == null &&
                result[validUrl].visitors[key2].passport_number == null &&
                result[validUrl].visitors[key2].contact_number != null
              ) {
                this.$store.dispatch(
                  "visitor/addVisitor",
                  result[validUrl].visitors[key2]
                );
              }
            }
          } else {
            this.visitorsList = [];
          }

          if (result[validUrl]["total"] !== undefined) {
            this.totalRows = parseInt(result[validUrl]["total"]);
          }

          if (result[validUrl]["page"] !== undefined) {
            this.currentPage = parseInt(result[validUrl]["page"]);
          }

          if (result[validUrl]["size"] !== undefined) {
            this.perPage = parseInt(result[validUrl]["size"]);
          }
        })
        .catch((err) => {
          this.busy = false;
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    // search visitor
    getSearchVisitor: function () {
      this.$store.dispatch("visitor/resetVisitor");

      let $this = this;
      let query = {
        keyword: $this.searchForm.keyword
      };

      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visitor/search/?";

      let queryString = Object.keys(query)
        .map((key3) => key3 + "=" + query[key3])
        .join("&");

      if (queryString != "") {
        API_URL += queryString;
        const client = this.$root.getAjaxFetchClient();
        client
          .getRequest(API_URL)
          .then((data) => {
            let result = data.result;
            let validUrl = "";
            if (result != undefined) {
              outer_loop: for (let key4 in result) {
                if (result[key4] != undefined) {
                  validUrl = key4;
                  break outer_loop;
                }
              }

              if (result[validUrl] !== null) {
                for (let key5 in result[validUrl]) {
                  result[validUrl][key5]["blocklist"] = null;
                  result[validUrl][key5]["is_disabled"] = false;
                  result[validUrl][key5]["has_history"] = false;
                  result[validUrl][key5]["disabled_update"] = false;
                  result[validUrl][key5]["is_block_disabled"] = false;

                  this.$store.dispatch(
                    "visitor/getVisitHistory",
                    result[validUrl][key5]
                  );

                  if (
                    result[validUrl][key5].nric_number != null ||
                    result[validUrl][key5].passport_number != null ||
                    result[validUrl][key5].contact_number != null
                  ) {
                    this.$store.dispatch(
                      "visitor/getCheckBlock",
                      result[validUrl][key5]
                    );

                    this.$store.dispatch(
                      "visitor/getVmsPreferences",
                      result[validUrl][key5]
                    );

                    this.$store.dispatch(
                      "visitor/addVisitor",
                      result[validUrl][key5]
                    );
                  }

                  if (
                    result[validUrl][key5].nric_number == null &&
                    result[validUrl][key5].passport_number == null &&
                    result[validUrl][key5].contact_number == null
                  ) {
                    this.$store.dispatch(
                      "visitor/addVisitor",
                      result[validUrl][key5]
                    );
                  }

                  if (
                    result[validUrl][key5].nric_number == null &&
                    result[validUrl][key5].passport_number == null &&
                    result[validUrl][key5].contact_number != null
                  ) {
                    this.$store.dispatch(
                      "visitor/addVisitor",
                      result[validUrl][key5]
                    );
                  }
                }

                this.search = true;
                this.busy = false;
              } else {
                this.search = true;
                this.busy = false;
                this.visitorsList = [];
              }
            } else {
              this.search = true;
              this.busy = false;
              this.visitorsList = [];
            }

            this.$v.$reset();
          })
          .catch((err) => {
            console.log("err");
            console.log(err);
          });
      } else {
        this.search = true;
        this.busy = false;
        this.$v.$reset();
        $this.visitorsList = [];
      }
    },
    clearSearch: function () {
      this.search = false;
      this.searchForm.keyword = null;
      this.$route.query.keyword = null;
      this.$route.query.keyword = null;
      this.visitorsList = [];
      this.$router.push({ name: "visitors" });
      //this.getVisitorsList();
    },
    //get partial
    getPartial(input) {
      let length = input.length;
      let input2 = input.substring(0, length - 4).split("");
      let input3 = input.substring(length - 4);

      let output = [];
      input2.forEach((letter) => {
        letter = "*";
        output.push(letter);
      });

      let answer = output.join("") + input3;
      return answer;
    },
    // custom id
    getCustomId(data) {
      let contact_number = data.contact_number;
      let length1 = contact_number.length;
      contact_number = contact_number.substring(length1 - 4);
      //let email = data.email.substring(4, 0);
      let custom_id = null;
      if (data.passport_number != null) {
        let length2 = data.passport_number.length;
        let passport_number = data.passport_number.substring(length2 - 4);
        custom_id = contact_number + passport_number;

        return custom_id;
      } else {
        let length3 = data.nric_number.length;
        let nric_number = data.nric_number.substring(length3 - 4);
        custom_id = contact_number + nric_number;

        return custom_id;
      }
    },

    handleTitle(value) {
      if (value) {
        return "Unblock to edit the user";
      } else {
        return "Update Visitor";
      }
    },
    //handle page change
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    },

    // select visitor in checkbox
    onRowSelected: function (items) {
      this.selected = items;
    },
    selectAllRows: function () {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected: function () {
      this.$refs.selectableTable.clearSelected();
    },
    toggleAll: function (checked) {
      if (checked) {
        this.selectAllRows();
      } else {
        this.clearSelected();
      }
    },

    // stop camera
    stopCameraModal: function () {
      let $this = this;
      let tracks = null;
      if ($this.$refs?.referencesChild?.$refs?.cameraView) {
        if ($this.$refs?.referencesChild?.$refs?.cameraView?.lastChild) {
          tracks =
            $this.$refs?.referencesChild?.$refs?.cameraView?.lastChild?.srcObject.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
        }
      }
    },

    // show image
    showImage: function (img) {
      let $this = this;
      if ((img == null) | (img == "")) {
        return $this.defaultImage;
      } else {
        return `data:image/jpeg;base64,${img}`;
      }
    },
    // show add visitor
    showAddVisitorForm: function () {
      this.contact_number = null;
      this.selectedVisitorGender = null;

      this.formAdd.face_image = null;

      this.showUploadPhoto = false;
      this.showTakePhoto = false;
      this.updateShowUploadPhoto = false;
      this.updateShowTakePhoto = false;

      this.selectedIDName = null;
      this.selectedIDType = "passport_number";

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    // show update visitor
    showUpdateVisitorForm: function (item, index) {
      let $this = this;
      $this.showTakePhoto = false;
      $this.showUploadPhoto = false;
      $this.updateShowTakePhoto = false;
      $this.updateShowUploadPhoto = true;

      $this.formUpdateID = item.id;
      $this.formUpdate.name = item.name;
      $this.formUpdate.email = item.email;
      $this.formUpdate.face_image = item.face_image;
      $this.formUpdate.profile.gender = item.profile.gender;
      $this.formUpdate.profile.car_plate_no = item.profile.car_plate_no;
      $this.formUpdate.contact_number =
        item?.contact_number?.substring(3) ?? null;
      $this.formUpdate.profile.fingerprint.template1 =
        item?.profile?.fingerprint?.template1;
      $this.formUpdate.profile.fingerprint.template2 =
        item?.profile?.fingerprint?.template2;
      $this.update_company_visitor = item?.profile?.company
        ? item.profile.company
        : item.additional_metadata.company;
      $this.formUpdate.profile.company = item?.profile?.company
        ? item.profile.company
        : item.additional_metadata.company;

      // current code
      if (
        item.passport_number != null &&
        (item.nric_number == null || item.nric_number == "")
      ) {
        $this.formUpdate.nric_number = null;
        $this.selectedIDType = "passport_number";
        $this.selectedIDName = item.passport_number;
        $this.formUpdate.passport_number = item.passport_number;
      } else if (item.nric_number != null && item.passport_number != null) {
        $this.selectedIDType = "nric_number";
        $this.selectedIDName = item.nric_number;
        $this.formUpdate.nric_number = item.nric_number;
        $this.selectedIDType = "passport_number";
        $this.selectedIDName = item.passport_number;
        $this.formUpdate.passport_number = item.passport_number;
      } else if (item.nric_number != null && item.passport_number == null) {
        $this.selectedIDType = "nric_number";
        $this.selectedIDName = item.nric_number;
        $this.formUpdate.nric_number = item.nric_number;
        $this.formUpdate.passport_number = null;
      } else if (item.passport_number == null && item.nric_number == null) {
        $this.selectedIDType = "passport_number";
        $this.formUpdate.nric_number = null;
        $this.formUpdate.passport_number = null;
      }
    },

    // validation
    validateFormSearch(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.searchForm[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },

    // delete visitor
    deleteVisitor: function (visitor_id, visitor_data) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visitor/?visitor_id=" +
        visitor_id;
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL, visitor_data)
        .then((data) => {
          $this.$store.dispatch("visitor/deleteVisitor", visitor_id);
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Deleted visitor successfully",
            message_type: "success"
          });
          if (this.searchForm.keyword != undefined) {
            this.getSearchVisitor();
          } else {
            this.getVisitorsList();
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    confirmDelete: function (visitor_data, index) {
      // e.preventDefault();
      let $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm deleting " + visitor_data.name + "?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            $this.deleteVisitor(visitor_data.id, visitor_data);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    // step 1 - confirm block
    confirmBlock: function (data_to_block) {
      let title = "";
      let message = "";

      if (data_to_block.is_blocklisted) {
        title = `Confirm unblock ${data_to_block.name}?`;
        message = `This will remove ${data_to_block.name} from the block-list`;
      } else {
        title = `Confirm block ${data_to_block.name}?`;
        message = `This will remove all the current access that have been given to ${data_to_block.name}. This action cannot be reverted.`;
      }

      this.$bvModal
        .msgBoxConfirm(message, {
          title: title,
          okTitle: "Confirm",
          cancelTitle: "Cancel",
          centered: true
        })
        .then((value) => {
          if (value) {
            let param = {};

            if (data_to_block.blocklist != null) {
              if (data_to_block.blocklist.type == "PASSPORT") {
                param["custom_id"] = null;
                param["nric_number"] = null;
                param["passport_number"] = data_to_block.blocklist.data;
              }

              if (data_to_block.blocklist.type == "NRIC") {
                param["custom_id"] = null;
                param["nric_number"] = data_to_block.blocklist.data;
                param["passport_number"] = null;
              }

              if (data_to_block.blocklist.type == "CUSTOM_ID") {
                param["custom_id"] = data_to_block.blocklist.data;
                param["nric_number"] = null;
                param["passport_number"] = null;
              }
            } else {
              if (
                this.getPreferences.additional_metadata.vms_pdpa_setting ==
                "save_partial"
              ) {
                param["custom_id"] = data_to_block.custom_id;
                param["nric_number"] = null;
                param["passport_number"] = null;
              } else {
                if (
                  data_to_block.passport_number != null &&
                  data_to_block.passport_number != ""
                ) {
                  param["custom_id"] = null;
                  param["nric_number"] = null;
                  param["passport_number"] = data_to_block.passport_number;
                } else {
                  param["custom_id"] = null;
                  param["nric_number"] = data_to_block.nric_number;
                  param["passport_number"] = null;
                }
              }
            }

            if (data_to_block.is_blocklisted) {
              //unblock visitor
              data_to_block.is_blocklisted = false;
              data_to_block["disabled_update"] = false;

              console.log("param", param);
              console.log("data_to_block", data_to_block);

              this.unblockVisitor(param, data_to_block);
            } else {
              //block visitor
              data_to_block.is_blocklisted = true;
              data_to_block["disabled_update"] = true;

              console.log("param", param);
              console.log("data_to_block", data_to_block);

              this.blockVisitor(param, data_to_block);
            }
          } else {
            if (this.$route.query.keyword != undefined) {
              //get auto search visitor
              this.searchForm.keyword = this.$route.query.keyword;
              this.getSearchVisitor();
            } else {
              // get visitor
              this.getVisitorsList();
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    // step 2 - unblock visitor
    unblockVisitor: function (param, data_to_block) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/api/visitorservice/blocklist/remove/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL, param)
        .then((data) => {
          if (data.detail != undefined) {
            $this.blockErrorMessage = data.detail;
          } else {
            let result = data.result;
            let validUrl = "";
            outer_loop: for (let key in result) {
              validUrl = key;
              break outer_loop;
            }
            if (result[validUrl] === null) {
              // update visitor to store
              this.$store.dispatch("visitor/updateVisitor", {
                id: data_to_block.id,
                data: {
                  face_image: data_to_block.face_image,
                  name: data_to_block.name,
                  email: data_to_block.email,
                  nric_number: data_to_block.nric_number,
                  passport_number: data_to_block.passport_number,
                  contact_number: data_to_block.contact_number,
                  custom_id: data_to_block.custom_id,
                  has_history: data_to_block.has_history,
                  is_blocklisted: data_to_block.is_blocklisted,
                  disabled_update: data_to_block.disabled_update,
                  profile: {
                    gender: data_to_block.profile.gender,
                    car_plate_no: data_to_block.profile.car_plate_no,
                    company: data_to_block.profile.company
                  }
                }
              });

              this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text:
                  "Visitor " +
                  data_to_block.name +
                  " has been removed from block-list",
                message_type: "success"
              });
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    // step 2 - block visitor
    blockVisitor: function (param, data_to_block) {
      let $this = this;
      let API_URL2 = $this.getAPIServerURL + "/api/visitorservice/blocklist/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL2, param)
        .then((data) => {
          if (data.detail != undefined) {
            $this.blockErrorMessage = data.detail;
          } else {
            let result = data.result;
            let validUrl = "";
            outer_loop: for (let key in result) {
              validUrl = key;
              break outer_loop;
            }

            if (result[validUrl] === null) {
              // update visitor to store
              this.$store.dispatch("visitor/updateVisitor", {
                id: data_to_block.id,
                data: {
                  face_image: data_to_block.face_image,
                  name: data_to_block.name,
                  email: data_to_block.email,
                  nric_number: data_to_block.nric_number,
                  passport_number: data_to_block.passport_number,
                  contact_number: data_to_block.contact_number,
                  custom_id: data_to_block.custom_id,
                  has_history: data_to_block.has_history,
                  is_blocklisted: data_to_block.is_blocklisted,
                  disabled_update: data_to_block.disabled_update,
                  profile: {
                    gender: data_to_block.profile.gender,
                    car_plate_no: data_to_block.profile.car_plate_no,
                    company: data_to_block.profile.company
                  }
                }
              });

              this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text:
                  "Visitor " +
                  data_to_block.name +
                  " has been blocked successfully",
                message_type: "success"
              });
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    // route visit history
    visitHistory: function () {
      this.$router.push({ name: "visit-history" }).catch(() => {});
    }
  }
};
</script>

<style lang="scss">
@import "../App.scss"; //"./App.scss";
</style>
